import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { TfiSearch } from "react-icons/tfi";
import s from './search.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { useGetPlacesMutation } from '../../../app/slices/sliceAddressApi';
import { activeAddressReducer, housesReducer, setFlatsReducer, streetsReducer } from '../../../app/slices/adressSlice';
import { errorsReducer, loadingReducer } from '../../../app/slices/pagesSlice';


type Props = {
  name: string
  index: number
  placeId?: string
  show?: boolean
}

const Search = ({ name, index, placeId, show }: Props) => {

  const token = useAppSelector(state => state.login.token)
  const [showSearch, setShowSearch] = useState<boolean[]>([false, false, false, false])
  const [activeSeach, setActiveSeach] = useState<[string, string, boolean, string][]>([['', '', false, '']])
  const dispatch = useAppDispatch()
  const [getData, { isLoading, isError }] = useGetPlacesMutation()
  const [input, setInput] = useState<[string, string, string, string]>(['', '', '', ''])

  const options = {
    includeScore: true,
    keys: ['0']
  }

  const fuse = new Fuse(activeSeach, options)
  const result = fuse.search(input[index])
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    if (show) setShowSearch([true, true, true, false])
  }, [show])
  useEffect(() => {
    if (showSearch[index]) {


      if (placeId) getData({ token, placeId }).unwrap()
        .then(res => {


          setActiveSeach(res.data.map(el => [el.place_name, el.place_id, el.children, el.place_type_name]))
        }
        )

    }
  }, [showSearch, dispatch, index, placeId, getData, token])



  const onClick = async (i: number) => {

    if (i === index) setShowSearch(showSearch.map((v, k) => {
      if (i === k) return !v
      return v
    }))
    if (!showSearch[i]) {
      if (index === 1) dispatch(activeAddressReducer(['1d678670-3617-11ef-91b8-0000000004c1', undefined, undefined]))


    }


  }
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    let a: [string, string, string, string] = [...input]
    a[index] = e.currentTarget.value
    setInput([...a])

  }
  useEffect(() => {


    if (input[index].length > 0) {

      let a = result.filter(item => (item.score && item.score < 1 / input[index].length / 2)).map(el => el.item)

      let b: [string, string, boolean, string][] = [['', '', false, '']]

      if (index === 1 && a.length > 0) dispatch(streetsReducer(a))
      else if (index === 1) dispatch(streetsReducer(b))
      if (index === 2 && a.length > 0) dispatch(housesReducer(a))
      else if (index === 2) dispatch(housesReducer(b))
      if (index === 3 && a.length > 0) dispatch(setFlatsReducer(a))
      else if (index === 3) dispatch(setFlatsReducer(b))
    } else {



      if (index === 1) dispatch(streetsReducer(activeSeach))
      if (index === 2) dispatch(housesReducer(activeSeach))
      if (index === 3) dispatch(setFlatsReducer(activeSeach))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])



  return (
    <div className={s.headerTytle}>
      <span style={showSearch[index] ? { display: 'none' } : undefined}>{name}</span>
      <input className={showSearch[index] ? s.search : s.searchHidden} type="text" id="Search" placeholder={name} title="Type in a name"
        value={input[index]} onChange={onChange}
      />
      <TfiSearch onClick={() => {
        onClick(index)
      }} className={s.iconLupa} />
    </div>
  )
}

export default Search