import React, { useEffect, useState } from 'react'
import { TfiArrowLeft } from "react-icons/tfi";
import { TfiArrowRight } from "react-icons/tfi";
import s from './pages.module.css'
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { pagesNumberReducer } from '../../app/slices/dataSlice';
import { pageReducer } from '../../app/slices/pagesSlice';
type Props = {}

const NumberPages = (props: Props) => {
    const total = useAppSelector(state => state.data.data.total)
    const { limit } = useAppSelector(state => state.data)
    const { currPage, arrPages } = useAppSelector(state => state.pages.pages)
    const dispatch = useAppDispatch()
    const allPages = Math.ceil(total / limit)
    const [show, setShow] = useState(false)
    const [input, setInput] = useState<string | number>(arrPages[currPage])


    useEffect(() => {
        
        if (allPages === 1) dispatch(pageReducer({ currPage, arrPages: [1] }))
        if (allPages === 2) dispatch(pageReducer({ currPage, arrPages: [1, 2] }))
        if (allPages === 3) dispatch(pageReducer({ currPage, arrPages: [1, 2, 3] }))
        if (allPages > 3) {

            setShow(true)
        }
        if (arrPages[0] > allPages - 3) setShow(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currPage, allPages, arrPages[0]])


    useEffect(() => {
        dispatch(pagesNumberReducer({ limit, offset: limit * (arrPages[currPage] - 1) }))
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currPage, arrPages, limit])


    const onClick = (i: number) => {


        if (i === 0) {
            if (arrPages[0] !== 1) {
                let a = arrPages.map(el => el - 1)
                dispatch(pageReducer({ currPage: 1, arrPages: [...a] }))
            } else {
                dispatch(pageReducer({ currPage: 0, arrPages }))

            }
        }
        if (i === 1) {
            dispatch(pageReducer({ currPage: 1, arrPages }))
        }
        if (i === 2) {
            if (arrPages[2] < allPages) {
                let a = arrPages.map(el => el + 1)
                dispatch(pageReducer({ currPage: 1, arrPages: [...a] }))
            } else dispatch(pageReducer({ currPage: 2, arrPages }))

        }
        if (i === allPages) {

            dispatch(pageReducer({ currPage: 2, arrPages: [allPages - 2, allPages - 1, allPages] }))

            setShow(false)
        }
    }


    const setPage = () => {
        const numberPage = +input
        if (numberPage <= allPages && numberPage > 0 && typeof (numberPage) === 'number') {
            if (allPages === 1) dispatch(pageReducer({ currPage: 0, arrPages: [1] }))
            if (allPages === 2 && numberPage < 3) dispatch(pageReducer({ currPage: numberPage - 1, arrPages: [1, 2] }))
            if (allPages === 3 && numberPage < 4) dispatch(pageReducer({ currPage: numberPage - 1, arrPages: [1, 2, 3] }))
            if (allPages > 3) {
                dispatch(pageReducer({ currPage: 2, arrPages: [allPages - 2, allPages - 1, allPages] }))
                if (numberPage === allPages) dispatch(pageReducer({ currPage: 2, arrPages: [allPages - 2, allPages - 1, allPages] }))
                else {
                    dispatch(pageReducer({ currPage: 1, arrPages: [numberPage - 1, numberPage, numberPage + 1] }))

                }
            }
        } else setInput('')


    }



    const pages = arrPages.map((el, i) => {
        return <span key={i + el} style={(i === currPage) ? { fontWeight: 'bold' } : undefined} className={s.page1}
            onClick={() => { onClick(i) }}
        >{el}</span>
    })

    return (
        <div className={s.pagesTotal}>
            <div className={s.blockLeft}>
                <div className={s.pagesLeft}>
                    <span className={s.pageL}>Страница</span>
                    <span className={s.pageL}>{arrPages[currPage]}</span>
                    <span className={s.pageL}>из</span>
                    <span className={s.pageL}>{allPages}</span>
                </div>
                <div className={s.pagesAmount}>
                    <span className={s.pageTxt}>Показать</span>
                    <select className={s.pagePart} value={limit} name="pages" id="pages" onChange={(e) => {
                        dispatch(pagesNumberReducer({ limit: +e.currentTarget.value, offset: 0 }));
                        dispatch(pageReducer({ currPage: 0, arrPages: [1, 2, 3] }))
                    }}>

                        <option value={20} >20</option>
                        <option value={40} >40</option>
                        <option value={60} >60</option>
                        <option value={80} >80</option>
                        <option value={100} >100</option>
                    </select>


                </div>
            </div>


            <div className={s.blockRight}>
                <div className={s.pagesRight}>
                    <TfiArrowLeft className={s.iconLeft} onClick={() => { onClick(currPage - 1) }} />

                    {pages}
                    {show && <>
                        <span className={s.page} onClick={() => { onClick(currPage + 1) }}>...</span>
                        <span className={s.page} onClick={() => onClick(allPages)} >{allPages}</span>
                    </>

                    }
                    <TfiArrowRight className={s.iconRight} onClick={() => { onClick(currPage + 1) }} />
                </div>


                <div className={s.pageTotal}>
                    <span className={s.textTotal}>Перейти на</span>
                    <input type="text" value={input} onChange={(e) => {
                        setInput(e.currentTarget.value)

                    }

                    } onKeyDown={(e) => {
                        if (e.code === 'Enter') setPage()
                    }} className={s.choiceNumber} />
                    <span className={s.textTotal}>страницу</span>
                    <button onClick={() => setPage()} className={s.pageTotalBtn}>Перейти</button>
                </div>
            </div>



        </div>
    )
}

export default NumberPages