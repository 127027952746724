import React, { useEffect, useState } from 'react'
import s from './mainPage.module.css'
import NavBar from './NavBar'
import FirstPages from './pages/FirstPages'
import { useGetDashboardQuery } from '../app/slices/sliceLogApi'
import logo from '../img/logo.png'
import { useAppDispatch, useAppSelector } from '../app/Hooks'
import { dataReducer } from '../app/slices/dataSlice'
import FiltrPage from './pages/FiltrPage'
import { loadingReducer } from '../app/slices/pagesSlice'
import Dashboard from './pages/Dashboard'
import Address from './pages/Address/Address'
import Reports from './pages/Reports/Reports'
import MainSearch from './pages/seach/MainSearch'
import Error500 from './Error500'
import Preloder2 from './Preloder/Preloder'


type Props = {
  resolve?: boolean 
}



const MainPage = ({resolve}: Props) => {

  const token = useAppSelector(state => state.login.token)
  const offset = useAppSelector(state => state.data.offset)||1
  const limit = useAppSelector(state => state.data.limit)
  const { loading, errors } = useAppSelector(state => state.pages)
  const queryString = useAppSelector(state => state.data.queryString)
  const activePage = useAppSelector(state => state.pages.activePage)
  const getData = useGetDashboardQuery({ token, offset, limit, queryString })
  const [filterShow, setFilterShow] = useState(false)
  const dispatch = useAppDispatch()
  const [preloader, setPreloader] = useState(false)

  useEffect(() => {
    if (getData.data) {

      
      dispatch(dataReducer(getData.data))

    }
  }, [dispatch, getData.data])


console.log(offset);


  useEffect(() => {
    dispatch(loadingReducer(getData.isFetching))
  }, [getData.isFetching, dispatch])

  useEffect(() => {
    if (loading) {
      setPreloader(true)
      setTimeout(() => {
        setPreloader(false)
      }, 1000)
    }

  }, [loading])
  const activeNavPage = (i: number) => {


    switch (i) {
      case 0: return <Dashboard />

      case 1: return <FirstPages showFilter={setFilterShow} isloader={preloader} />

      case 2: return <Address />

      case 3: return <Reports />

      default: <Dashboard />
    }
  }



  return (
    <div className={s.mainPage}>
      {errors ? <Error500 /> : <>
        <div className={s.header}>
          <div className={s.logoBlock}>

            <img className={s.logo} src={logo} alt="logo" />

          </div>

          {activePage === 1 && <MainSearch />}
        </div>

        <div className={s.conteiner}>
          <NavBar resolve={resolve} />


          <div className={!preloader ? s.pages : s.pagesLoading} >

            {preloader && <Preloder2 />}

            {activeNavPage(activePage)}
            {(filterShow && activePage === 1) && <FiltrPage close={() => setFilterShow(false)} />}


          </div>

        </div>

      </>}
    </div>
  )
}

export default MainPage